/** @jsx jsx */
import React from "react"

import { jsx } from "theme-ui"
import {
  Hero,
  WhatCensus,
  MapContainer,
  AlertBanner,
  Layout,
  ServiceContactInfo,
  Header,
  SEO,
} from "../components"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const IndexPage = () => {
  const headerRef = React.useRef()

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -150 && !headerRef.current.visible) {
      headerRef.current.setAttribute("style", "transform: translateY(0);")
      headerRef.current.visible = true
    }
    // Hide Site Header neap Map for maximum visiblity
    if (currPos.y < -2000 && headerRef.current.visible === true) {
      headerRef.current.setAttribute("style", "transform: translateY(-110%);")
      headerRef.current.visible = false
    }
    if (currPos.y > -150 && headerRef.current.visible === true) {
      headerRef.current.setAttribute("style", "transform: translateY(-110%);")
      headerRef.current.visible = false
    }
  })

  return (
    <Layout>
      <SEO title="San Francisco 2020 Census"></SEO>
      <div
        ref={headerRef}
        sx={{
          position: "fixed",
          width: "100%",
          top: 0,
          transform: "translateY(-110%)",
          zIndex: 20,
          paddingY: 20,
          background: "white",
          transition: "all 350ms cubic-bezier(0, 0.66, 0.77, 1.08)",
          willChange: "transform",
          boxShadow: "elevation1",
        }}
      >
        <Header></Header>
      </div>
      <Hero />
      <WhatCensus />
      <AlertBanner />
      <div id="helpCenterFinder">
        <MapContainer />
      </div>
      <ServiceContactInfo />
    </Layout>
  )
}

export default IndexPage
